.msr-font-setting-menu {
  width: 200px;
  display: flex;
  justify-content: space-around;

  &.column {
    flex-direction: column;
  }

  button {
    @include theme-aware-inverted("color", "primary-foreground-color");
    cursor: pointer;
    min-width: 70px;
    min-height: 50px;
    background-color: transparent;
    border: none;
  }

  button:hover, button.active {
    @include theme-aware-inverted("color", "primary-accent-color");

    svg {
      @include theme-aware-inverted("fill", "primary-accent-color");
      @include theme-aware-inverted("stroke", "primary-accent-color");
    }
  }
}
