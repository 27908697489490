$checkmark-size: 15px;

.msr-radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  &.disabled {
    color: $secondary-foreground-color;
    cursor: not-allowed;
  }

  &.highlight {
    @include theme-aware("color", "secondary-accent-color");
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:focus-within .checkmark {
    outline: -webkit-focus-ring-color auto 1px;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: $checkmark-size;
    width: $checkmark-size;
    border: solid 1px;
    @include theme-aware("border-color", "primary-foreground-color");
    border-radius: 50%;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    @include theme-aware("background-color", "secondary-accent-color");
  }
}
