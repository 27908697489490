.msr-tab-link {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 2rem;
  text-decoration: underline;
  @include theme-aware("color", "secondary-accent-color");

  &:hover {
    cursor: pointer;
  }

  @include for-print {
    display: none;
  }
}
