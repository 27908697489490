.msr-course-header {
  p {
    margin: 0;

    a {
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;

    h1 {
      margin: 10px 0;
    }
  }
}
