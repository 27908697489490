.msr-tabs {
  display: flex;
  flex-direction: row;
  margin: 2rem 0 1rem 0;
  border-bottom: 3px solid;
  @include theme-aware("border-color", "primary-accent-color");

  :nth-child(1) {
    z-index: 3;
  }
  :nth-child(2) {
    z-index: 2;
  }

  :nth-child(3) {
    z-index: 1;
  }

  & > button {
    padding: 0.25rem 1.5rem;
    text-align: center;
    border: 3px solid;
    @include theme-aware("border-color", "primary-accent-color");
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    @include theme-aware("background-color", "primary-accent-color");
    margin-right: -1rem;
    @include theme-aware("color", "primary-background-color");
    font-weight: 600;
    font-size: 1rem;

    &:not(.selected) {
      filter: brightness(80%);
      &:hover {
        transition-duration: 0.4s;
        cursor: pointer;
        filter: brightness(70%);
      }
    }
  }
  .selected {
    z-index: 10;
  }

  @include for-small-screen {
    margin-top: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

    & > button {
      width: 45%;
      border: none;
      padding: 0.5rem 1rem;
      margin: 0 0 1rem 0;
      border-radius: 4px;
      filter: brightness(60%);

      &.selected {
        display: none;
      }
    }
  }
}

.hide {
  display: none;
}
