@import "../../../styles/themes";

.msr-app-layout {
  display: grid;
  height: 100vh;
  max-width: 100vw;
  grid-template-columns: $sidebar-width auto;
  grid-template-rows: max-content auto;
  grid-template-areas: "app-header app-header" "sidebar-menu app-content";

  @include for-phone-screen {
    grid-template-columns: 100vw;
    grid-template-rows: max-content 0 auto;
    grid-template-areas: "app-header" "sidebar-menu" "app-content";
  }

  @include for-print {
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-template-areas: "app-content";

    .msr-app-header, .msr-sidebar-menu {
      display: none;
    }
  }

  .msr-app-header {
    grid-area: app-header;
  }

  .msr-sidebar-menu {
    grid-area: sidebar-menu;
  }

  .msr-app-content {
    grid-area: app-content;
  }
}
