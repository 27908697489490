.msr-quiz {
  margin: 20px 0;

  h3 {
    @include theme-aware("color", "primary-accent-color");
    font-weight: normal;
    font-size: 1rem;
    margin-top: 0;
  }

  .msr-quiz-navigation {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .msr-btn {
      border-radius: 4px;
      width: 7rem;
      &:focus {
        filter: brightness(100%);
      }
      &:hover {
        filter: brightness(70%);
      }
      &:disabled {
        filter: brightness(50%);
        cursor: not-allowed;
      }
    }
  }
}

.msr-question {
  margin: 20px 0 50px 0;

  hr {
    margin: 20px 0;
  }

  h4 {
    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }
}
