.msr-multiple-choice-feedback {
  line-height: 1.3rem;

  hr {
    margin: 2.5rem 0;
  }

  .result {
    font-weight: bolder;
    font-size: 130%;
  }

  .answer {
    font-weight: bold;
    font-size: 115%;
  }

  .check-mark-icon {
    height: 1.5rem;
    width: auto;
    margin-bottom: -0.3rem;
    stroke: #2bc20e;
    stroke-width: 2px;
    margin-right: 0.75rem;
  }

  .close-icon {
    height: 1.5rem;
    width: auto;
    margin-bottom: -0.3rem;
    stroke: #a00000;
    stroke-width: 1px;
    fill: #a00000;
    margin-right: 0.75rem;
  }
}
