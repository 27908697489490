.msr-sidebar-navigation {
  .msr-sidebar-navigation-header {
    @include theme-aware('color', 'primary-accent-color');
    font-weight: 400;
    font-size: 1.6rem;
    margin-top: 0;
  }

  .msr-sidebar-navigation-item {
    display: flex;
    gap: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    @include theme-aware('color', 'secondary-foreground-color');

    .item-title {
      line-height: normal;
      margin: 0.5rem 0;
    }

    .item-title-prefix {
      min-width: 1.5rem;
    }

    .item-subtitle {
      margin: 0;
      font-size: .8rem;
      line-height: .8rem;
    }

    &:hover, &.open {
      @include theme-aware('color', 'primary-accent-color');
      font-weight: 500;
    }
  }

  .msr-accordion-content {
    .msr-sidebar-navigation-item {
      padding-left: 30px;
    }
  }
}

