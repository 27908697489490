$primary-background-color: #111113;
$primary-foreground-color: #F5F5F5;
$secondary-background-color: #171719;
$secondary-foreground-color: #66696D;

$primary-background-color-light: #F5F5F5;
$primary-foreground-color-light: #232323;
$secondary-background-color-light: #FFFFFF;
$secondary-foreground-color-light: #66696D;

$msr-pink: #DB0A5B;
$msr-deep-beet: #7D2248;
$msr-yellow: #EAAA00;
$msr-rust: #C54644;
$msr-coral: #FF6A39;

$h1-color: $msr-pink;
$h1-color-light: $msr-deep-beet;

$heading-color: #F5F5F5;
$heading-color-light: #595959;

$header-height: 90px;
$header-title-height-small: 70px;
$header-height-small: $header-height + $header-title-height-small;
$header-logo-height: 50px;
$header-content-height: 40px;
$sidebar-width: 80px;
$content-width: calc(100vw - #{$sidebar-width} - 20px);
$content-height: calc(100vh - #{$header-height});

$msr-light-theme: (
        primary-background-color: $primary-background-color-light,
        primary-foreground-color: $primary-foreground-color-light,
        secondary-background-color: $secondary-background-color-light,
        secondary-foreground-color: $secondary-foreground-color-light,
        primary-accent-color: $msr-deep-beet,
        secondary-accent-color: $msr-rust,
        tertiary-accent-color: $msr-coral,
        h1-color: $h1-color-light,
        heading-color: $heading-color-light,
        primary-button: $msr-deep-beet,
        primary-button-background: $msr-deep-beet,
        primary-button-text: $primary-foreground-color,
        secondary-button: $msr-yellow,
        secondary-button-background: $msr-yellow,
        secondary-button-text: $primary-foreground-color-light,
);

$msr-dark-theme: (
        primary-background-color: $primary-background-color,
        primary-foreground-color: $primary-foreground-color,
        secondary-background-color: $secondary-background-color,
        secondary-foreground-color: $secondary-foreground-color,
        primary-accent-color: $msr-pink,
        secondary-accent-color: $msr-yellow,
        tertiary-accent-color: $msr-coral,
        h1-color: $h1-color,
        heading-color: $heading-color,
        primary-button: $msr-deep-beet,
        primary-button-background: $msr-deep-beet,
        primary-button-text: $primary-foreground-color,
        secondary-button: $msr-yellow,
        secondary-button-background: transparent,
        secondary-button-text: $msr-yellow,
);

$msr-themes: (
        light: $msr-light-theme,
        dark: $msr-dark-theme,
);

$msr-themes-inverted: (
        light: $msr-dark-theme,
        dark: $msr-light-theme,
);

$max-width-phone-screen: 499px;
$max-width-small-screen: 1024px;
$max-width-medium-screen: 1450px;

@mixin for-phone-screen {
  @media only screen and (max-width: $max-width-phone-screen) { @content; }
}

@mixin for-not-phone-screen {
  @media only screen and (min-width: $max-width-phone-screen) { @content; }
}

@mixin for-small-screen {
  @media only screen and (max-width: $max-width-small-screen) { @content; }
}

@mixin for-medium-screen {
  @media only screen and (min-width: $max-width-small-screen) and (max-width: $max-width-medium-screen) { @content; }
}

@mixin for-medium-and-smaller-screen {
  @media only screen and (max-width: $max-width-medium-screen) { @content; }
}

@mixin for-print {
  @media only print { @content; }
}

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'primary-background-color');
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $msr-themes {
    .msr-theme-#{$theme-name} & {
      #{$key}: map-get(map-get($msr-themes, $theme-name), $color)
    }
  }
}

@mixin theme-aware-inverted($key, $color) {
  @each $theme-name, $theme-color in $msr-themes-inverted {
    .msr-theme-#{$theme-name} & {
      #{$key}: map-get(map-get($msr-themes-inverted, $theme-name), $color)
    }
  }
}
