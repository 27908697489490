.msr-search-container {
  div:first-of-type,
  h2 + .msr-search-result {
    margin: 1rem 0;
  }

  .msr-search-result {
    article p:first-of-type {
      margin-top: 0;
    }
  }
}
