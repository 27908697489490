.msr-progress-bar {
  display: flex;
  @include theme-aware("background-color", "primary-foreground-color");
  @include theme-aware("color", "primary-background-color");
  height: 2rem;
  border-radius: 2rem;
  align-items: center;
  width: 100%;
  overflow: hidden;
  p {
    margin: {
      left: auto;
      right: 0.75rem;
    }
    font-size: 1.6rem;
  }

  &.small {
    p {
      display: none;
    }
    height: 1rem;
    border-radius: 1rem;
  }
}

.msr-current-progress {
  display: flex;
  border-radius: inherit;
  height: inherit;
  align-items: center;
  p {
    margin-left: 0.75rem;
  }
}
