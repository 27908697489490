.msr-app-header {
  @include theme-aware('background-color', 'primary-background-color');

  .msr-header-logo {
    margin: ($header-height - $header-logo-height)/2 10px;

    img {
      height: 100%;
      margin: auto;
      display: block;
    }
  }

  .msr-header-menu {
    padding: ($header-height - $header-content-height)/2 0;
    margin-left: auto;
    margin-right: 15px;
    display: flex;
  }
}
