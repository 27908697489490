.msr-accordion-button.open {
  @include theme-aware('color', 'primary-accent-color');

  span:last-of-type {
    background-image: none;
  }
}

.msr-accordion-content {
  display: none;
  overflow: hidden;
  height: fit-content;
  text-align: left;

  &.open {
    display: block;
  }
}

.msr-accordion-content.scroll {
  display: block;
  max-height: 0;
  transition: max-height .5s linear;

  &.open {
    overflow-y: auto;
    max-height: 500px;
  }
}
