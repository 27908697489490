.msr-app-header {
  display: grid;
  grid-template-rows: $header-height;
  grid-template-areas: "header-logo header-title header-subtitle header-menu";
  grid-template-columns: $sidebar-width max-content max-content auto;

  @include for-small-screen {
    grid-template-areas: "header-logo header-title header-menu" ". header-subtitle .";
    grid-template-columns: $sidebar-width auto auto;
  }

  @include for-phone-screen {
    grid-template-areas: "header-logo header-menu" "header-title header-title";
    grid-template-columns: $sidebar-width auto;

    .msr-header-subtitle {
      display: none;
    }
  }

  .msr-header-title {
    grid-area: header-title;
    padding-left: 1em;
    align-self: center;

    @include for-phone-screen {
      padding-bottom: .5em;
    }
  }

  .msr-header-subtitle {
    grid-area: header-subtitle;
    padding-left: 2em;
    align-self: center;

    @include for-small-screen {
      padding-left: 1rem;
      padding-bottom: .5em;
    }
  }

  .msr-header-logo {
    grid-area: header-logo;
  }

  .msr-header-menu {
    grid-area: header-menu;
  }
}
