.msr-dropdown {
  position: relative;
  display: inline-block;
  align-self: center;

  .msr-dropdown-btn {
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border: none;
    @include theme-aware("color", "secondary-foreground-color");

    svg {
      @include theme-aware("fill", "secondary-foreground-color");
      @include theme-aware("stroke", "secondary-foreground-color");
    }
  }

  &:hover, &:focus-within {
    .msr-dropdown-btn {
      @include theme-aware("color", "primary-accent-color");

      svg {
        @include theme-aware("fill", "primary-accent-color");
        @include theme-aware("stroke", "primary-accent-color");
      }
    }
  }

  &:hover .msr-dropdown-content, &:focus-within .msr-dropdown-content{
    display: block;
  }

  .msr-dropdown-content {
    @include theme-aware-inverted("background-color", "primary-background-color");
    @include theme-aware-inverted("color", "primary-foreground-color");
    display: none;
    position: absolute;
    padding: 10px;
    right: 0;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 99999999;
  }
}
