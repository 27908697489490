@import "themes";

h1 {
  @include theme-aware("color", "h1-color");
}

h2 {
  @include theme-aware("color", "heading-color");
  margin: 0;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;

  @include for-phone-screen {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h3 {
  @include theme-aware("color", "heading-color");
}

h4 {
  margin: 5px 0;
  font-weight: 400;
}

img {
  max-width: 100%;
}

a {
  @include theme-aware("color", "secondary-accent-color");
}

body {
  @include theme-aware("background-color", "secondary-background-color");
  @include theme-aware("color", "primary-foreground-color");
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5rem;
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ample-soft", "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
}

@import "accordion";
@import "appHeader";
@import "appHeaderLayout";
@import "article";
@import "button";
@import "card";
@import "chapterHeader";
@import "courseHeader";
@import "dropdown";
@import "fonts";
@import "fontSettingMenu";
@import "latex";
@import "menuItem";
@import "myCourses";
@import "preNextLink";
@import "question";
@import "radioButton";
@import "scrollbar";
@import "searchContainer";
@import "searchField";
@import "searchResult";
@import "sidebarMenu";
@import "sidebarNavigation";
@import "teaser";
@import "toggleSwitch";
@import "themeSwitcher";
@import "paymentForm";
@import "consent";
@import "progressBar";
@import "multipleChoiceResult";
@import "subchapterTabs";
@import "subchapterBottomNavigation";
@import "progressAccordion";
@import "courseProgress";
@import "subchapterBody";
@import "expandableText";
