.msr-theme-switcher {
  @include theme-aware('background-color', 'secondary-background-color');

  border: 2px solid;
  @include theme-aware("border-color", "secondary-foreground-color");

  &:hover {
    @include theme-aware('border-color', 'primary-accent-color');
  }

  span {
    background: url("../icons/moon.svg") center / 18px no-repeat;
    @include theme-aware('background-color', 'primary-accent-color');
  }

  input:checked + span {
    background-image: url("../icons/sun.svg");
  }
}
