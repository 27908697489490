@import "../../../styles/themes";

@mixin extra-bottom-padding-on-phone {
  @include for-small-screen {
    padding-bottom: 5rem !important;
  }
}

.msr-page {
  overflow: hidden;
}

.msr-page.simple .msr-page-content {
  overflow-y: scroll;
  height: 100%;

  .msr-page-content-main {
    max-width: $max-width-small-screen;
    margin: 0 auto;
    padding: 0 2rem 2rem;
    @include extra-bottom-padding-on-phone;
  }
}

.msr-page.grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 480px auto;
  grid-template-rows: min-content;
  grid-template-areas: "page-sidebar page-content";

  @include for-small-screen {
    overflow-y: scroll;
    grid-template-columns: $content-width;
    grid-template-areas: "page-content" "page-sidebar";
  }

  @include for-phone-screen {
    overflow-y: scroll;
    grid-template-columns: 100vw;
    grid-template-areas: "page-content" "page-sidebar";
  }

  @include for-medium-screen {
    grid-template-areas: "page-sidebar page-content";
    grid-template-columns: minmax(320px, 400px) auto;
  }

  @include for-print {
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-template-areas: "page-content";
    overflow: inherit;

    .msr-page-sidebar, .msr-page-content-aside {
      display: none;
    }
  }

  .msr-page-sidebar {
    grid-area: page-sidebar;
    @include extra-bottom-padding-on-phone;

    &.hide {
      display: none;
    }
  }

  .msr-page-content {
    grid-area: page-content;
  }

  .msr-page-scroll {
    overflow-y: scroll;
    height: $content-height;
    box-sizing: border-box;
    padding: 2rem;

    @include for-small-screen {
      padding: 1rem;
      overflow-y: unset;
      height: unset;
    }
  }
}
