.msr-progress-accordion-header {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 2rem;
  gap: 1rem;
  background-color: unset;
  align-items: center;
  color: unset;
  border: unset;

  p {
    transition-duration: 0.4s;
    text-align: start;
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;

    p {
      @include theme-aware("color", "primary-accent-color");
      font-weight: 700;
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
  }
}
