.msr-my-courses-header {
  position: relative;
  margin: 0 auto 40px auto;

  h1 {
    position: absolute;
    top: 20%;
    left: 10%;
    color: black;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: normal;
  }

  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }

  @include for-phone-screen {
    margin: 0 0 40px 0;

    h1 {
      font-size: 1.5rem;
      top: 5px;
      left: 15px;
      line-height: unset;
    }
  }
}

.msr-my-courses-body {
  h2 {
    margin: 10px 0;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
  }
}

.msr-close-modal-btn{
  font-weight: bold;
  font-size: 20px;
  float: right;
  border: 0;
  border-radius: 20px;
  margin: -10px -10px 0 0;
  @include theme-aware("color", "secondary-foreground-color");
  @include theme-aware("background-color", "primary-background-color");
}
