.msr-sidebar-menu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-right: 6px solid;
  @include theme-aware('border-color', 'primary-background-color');

  :nth-last-child(3) {
    margin-top: auto;
  }
}
