.msr-search-result {
  display: block;
  margin-top: 15px;
  padding: 5px;

  p {
    margin: 0;
    @include theme-aware("color", "primary-foreground-color")
  }

  a {
    text-decoration: none;
    border-bottom: solid 1px;
  }

  a:hover {
    border-color: transparent
  }

  h3 {
    margin: 0 0 5px;
  }

  &.active {
    @include theme-aware('border-color', 'secondary-accent-color');
    border-right: solid 2.5px;
  }
}
