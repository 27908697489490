.msr-btn {
  display: flex;
  border: 1px solid;
  width: 100%;
  padding: 10px 16px;
  cursor: pointer;
  transition-duration: 0.4s;
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover, &:focus {
    filter: brightness(70%);
  }

  &.disabled, &:disabled {
    filter: brightness(50%);
    cursor: not-allowed;
  }

  &.primary {
    @include theme-aware('border-color', 'primary-button');
    @include theme-aware('background-color', 'primary-button-background');
    @include theme-aware('color', 'primary-button-text');
  }

  &.secondary {
    border-radius: 4px;
    @include theme-aware('border-color', 'secondary-button');
    @include theme-aware('background-color', 'secondary-button-background');
    @include theme-aware('color', 'secondary-button-text');
  }

  &.tertiary {
    border: none;
    text-align: start;
    justify-content: left;
    padding: 0;
    margin: 1rem 0;
    font-weight: normal;
    background-color: transparent;
    @include theme-aware('color', 'secondary-foreground-color');

    &:focus, &:hover {
      font-weight: bold;
      filter: none;
      @include theme-aware('color', 'primary-accent-color');
    }
  }
}
