.msr-teaser {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  @include theme-aware("background-color", "primary-background-color");
  width: 100%;
  max-width: 100%;
  margin-bottom: 50px;

  img {
    height: 200px;
    min-width: 200px;
    object-fit: contain;
  }

  @include for-phone-screen {
    flex-direction: column;
    padding: 10px;
  }

  .msr-teaser-description {
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 1em 0 1em 1em;

    @include for-phone-screen {
      padding: 1em 0 0 0;
    }

    .msr-teaser-title {
      display: flex;
      justify-content: space-between;
      padding-right: 1rem;

      @include for-small-screen {
        flex-flow: column;
      }
    }

    article {
      width: 100%;

      h3 {
        margin: 0;
      }

      p {
        @include theme-aware("color", "primary-foreground-color");
      }
    }

    p {
      @include theme-aware("color", "primary-foreground-color");
      font-size: 0.9em;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-self: flex-end;
      margin: 0;

      svg {
        stroke: $msr-coral;
        fill: $msr-coral;
        height: 2.5em;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }

    .msr-teaser-actions {
      display: flex;
      align-self: flex-end;
      margin: 5px 0;
      gap: 1rem;

      @include for-small-screen {
        margin: 25px 0 0 0;
        width: 100%;
      }

      .msr-btn {
        min-width: 150px;
      }
    }
  }
}
