@import "themes";

@include for-not-phone-screen {
  ::-webkit-scrollbar {
    width: 6px;
    @include theme-aware('background-color', 'primary-background-color');
  }

  ::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: transparent;
    border-left-width: 1.5px;
    border-right-width: 1.5px;
    background-clip: content-box;
    @include theme-aware('background-color', 'secondary-foreground-color');

    &:hover {
      border-width: 0;
      @include theme-aware('background-color', 'primary-accent-color');
    }
  }
}
