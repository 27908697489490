.msr-html-from-string {
  .msr-html-serialized-image {
    text-align: center;
    overflow: auto;

    img {
      background-color: $secondary-background-color-light;
      height: 300px;
    }
  }

  .msr-html-serialized-table {
    border-collapse: collapse;
    margin: 1rem 0;
    width: 100%;

    td, th {
      border: 1px solid;
      @include theme-aware('border-color', 'secondary-foreground-color');
      text-align: left;
      padding: 0px 8px;
    }
  }

  h3 {
    @include theme-aware('color', 'primary-accent-color');
    @include theme-aware('border-color', 'primary-accent-color');
    border-bottom: 2px solid;
    margin-top: 2rem;
  }

  aside {
    padding: 15px;
    border-left: solid 2.5px;
    @include theme-aware('border-left-color', 'secondary-accent-color');
  }

  strong {
    @include theme-aware('color', 'heading-color');
  }

  .katex-display {
    margin: 1em 0 1em 1rem;
    text-align: left;

    & > .katex {
      text-align: left;
    }
  }

  .katex {
    .katex-display {
      font-size: 1.2rem;
    }

    .katex-mathml {
      display: none;
    }
  }
}
