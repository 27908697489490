$switch-width: 30px;
$switch-height: 21px;
$switch-gap: -1px;
$switch-color: rgba(0, 0, 0, 0.25);

.msr-toggle-switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  border: 2px solid;
  border-radius: 15px;
  margin: auto 15px;
  cursor: pointer;
  @include theme-aware('background-color', 'secondary-background-color');
  @include theme-aware("border-color", "secondary-foreground-color");

  &__dot {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    @include theme-aware('background-color', 'secondary-foreground-color');
    top: ($switch-height - 25px) / 2;
    left: - (25px / 2) + 5px;
    transition: all 0.3s;
  }

  &:focus-within > &__dot {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &__checkbox {
    height: 0;
    margin: 0;

    &:checked + .msr-toggle-switch__dot {
      left: $switch-width - (25px / 2) - 6px;
      @include theme-aware('background-color', 'primary-accent-color');
    }
  }
}