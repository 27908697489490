.card-wrapper {
  @include theme-aware("color", "secondary-foreground-color");
  @include theme-aware("background-color", "secondary-background-color");
  @include theme-aware("border-color", "secondary-foreground-color");
  border: 2px solid;
  padding: 10px 2px 0 10px;
  margin: 15px 0 15px 0;
  border-radius: 10px;
  height: 35px;

  &:focus,
  &:hover {
    @include theme-aware("border-color", "primary-accent-color");
    outline: none;
  }
}

.card-holder-input {
  @include theme-aware("color", "primary-foreground-color");
  @include theme-aware("background-color", "primary-background-color");
  @include theme-aware("border-color", "secondary-foreground-color");
  border: 2px solid;
  border-radius: 10px;
  padding-left: 15px;
  height: 40px;
  font-size: 18px;

  &::placeholder {
    @include theme-aware("color", "secondary-foreground-color");
  }

  &:focus,
  &:hover {
    @include theme-aware("border-color", "primary-accent-color");
    outline: none;
  }
}
