.msr-subchapter {
  &__repetition {
    padding-block: 2rem;

    &__title {
      margin-bottom: 1rem;
      font-size: x-large;
    }
  }
}
