@import "../../../styles/themes";

.msr-page.grid {
  .msr-page-content {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: minmax(480px, 720px) auto;
    grid-template-rows: min-content;
    grid-template-areas: "content-main content-aside";

    @include for-medium-and-smaller-screen {
      display: block;
    }

    .msr-page-content-main {
      grid-area: content-main;
    }

    .msr-page-content-aside {
      grid-area: content-aside;
      height: calc(#{$content-height} - 4rem);
      position: sticky;
      top: 0;

      @include for-medium-and-smaller-screen {
        position: unset;
        height: unset;
      }
    }
  }
}
