@mixin setMenuItemTheme($inverted: false) {
  @if $inverted {
    @include theme-aware-inverted("color", "secondary-foreground-color");

    &.highlight, &:hover, &:focus {
      @include theme-aware-inverted("color", "primary-accent-color");

      svg {
        @include theme-aware-inverted("fill", "primary-accent-color");
      }

      .msr-menu-item-invert{
        fill: transparent !important;
        @include theme-aware-inverted("stroke", "primary-accent-color");
      }
    }
  } @else {
    @include theme-aware("color", "secondary-foreground-color");

    &.highlight, &:hover, &:focus {
      @include theme-aware("color", "primary-accent-color");

      svg {
        @include theme-aware("fill", "primary-accent-color");
      }
      .msr-menu-item-invert{
        fill: transparent !important;
        @include theme-aware("stroke", "primary-accent-color");
      }
    }
  }
}

.msr-menu-item {
  padding: 8px;
  margin: 6px auto;

  text-decoration: none;
  display: flex;
  align-items: center;

  &.btn {
    width: 100%;
    font-size: 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &.btn.iconOnly {
    width: unset;
  }

  &.iconOnly {
    flex-direction: column;
    font-size: 12px;

    svg {
      height: 25px;
      margin: 0;
    }
  }

  svg {
    margin: auto 10px;
    width: 30px;
    height: 30px;
    fill: $secondary-foreground-color;
    stroke: transparent;
  }

  .msr-menu-item-invert{
    fill: transparent;
    stroke: $secondary-foreground-color;
  }

  &.highlight, &:hover, &:focus {
    outline: none;
  }

  @include setMenuItemTheme();

  &.themeInverted {
    @include setMenuItemTheme(true);
  }
}
