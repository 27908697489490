@mixin setSearchFieldTheme($inverted: false) {
  @if $inverted {
    input {
      @include theme-aware-inverted("color", "primary-foreground-color");
      @include theme-aware-inverted("background-color", "secondary-background-color");
      @include theme-aware-inverted("border-color", "secondary-foreground-color");

      &::placeholder {
        @include theme-aware-inverted("color", "secondary-foreground-color");
      }

      &:focus, &:hover {
        @include theme-aware-inverted('border-color', 'primary-accent-color');
      }
    }
  } @else {
    input {
      @include theme-aware("color", "primary-foreground-color");
      @include theme-aware("background-color", "secondary-background-color");
      @include theme-aware("border-color", "secondary-foreground-color");

      &::placeholder {
        @include theme-aware("color", "secondary-foreground-color");
      }

      &:focus, &:hover {
        @include theme-aware('border-color', 'primary-accent-color');
      }
    }
  }
}

.msr-search-field {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("../icons/search.svg") center / contain no-repeat;
  }

  input {
    padding-left: 40px;
    padding-right: 10px;
    height: 40px;
    width: 100px;
    border: 2px solid;
    border-radius: 10px;

    &:focus {
      width: initial;
    }

    &:focus, &:hover {
      outline: none;
    }
  }

  @include setSearchFieldTheme();

  &.themeInverted {
    @include setSearchFieldTheme(true);
  }

  &.fullWidth input {
    width: 100%;
  }

  &.large input {
    width: 400px;
    @include for-phone-screen {
      width: 100%;
    }
  }
}
