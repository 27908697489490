.msr-chapter-header {
  span {
    @include theme-aware('color', 'primary-accent-color');
    margin: 0;
  }

  &.small {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
