.msr-course-progress {
  &__header {
    margin-block: 2rem;

    > h3 {
      font-size: x-large;
    }
  }
  &__accordion {
    &__subchapter {
      padding-inline: 2rem 3rem;
      margin-block: 0;
    }

    &__chapter-test {
      display: flex;
      justify-content: space-between;
      padding-inline: 2rem 3rem;
    }
  }
}
