.msr-expandable-text {
  &__text {
    max-height: 250px;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(to bottom, transparent 50%, $secondary-background-color 100%);
    }

    &.open {
      max-height: unset;

      &::after {
        all: unset;
      }
    }
  }

  &__button {
    width: auto;
    display: flex;
    align-items: center;
    &__icon {
      display: flex;
      align-items: center;
    }
  }
}

.msr-theme-light {
  .msr-expandable-text {
    &__text::after {
      background: linear-gradient(to bottom, transparent 50%, $secondary-background-color-light 100%);
    }
  }
}
