.msr-font-standard {
  font-family: "proxima-nova", sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: "ample-soft", "proxima-nova", sans-serif;
  }
}

.msr-font-sans-serif {
  font-family: "proxima-nova", sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: "proxima-nova", sans-serif;
  }
}

.msr-font-serif {
  font-family: "Merriweather", serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: "Merriweather", serif;
  }
}

.msr-font-dyslectic {
  font-family: "opendyslexic", "proxima-nova", sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-family: "opendyslexic", "proxima-nova", sans-serif;
  }
}
